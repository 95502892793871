<template>
  <v-app class="authorization" style="overflow-y: hidden !important">
    <div class="main-container">
      <loaderCustom />
    </div>
  </v-app>
</template>

<script>
import { loaderCustom, mapActions } from "@isc/styleguide";
import ip from "ip";
const publicIp = require("what-is-my-ip-address");

export default {
  components: {
    loaderCustom,
  },
  data() {
    return {
      httpEndpoint:
        process.env.VUE_APP_API_GRAPHQL /* "http://localhost:3000/graphql" */,
    };
  },
  methods: {
    ...mapActions({
      getInfoClient: "settings/profile/GET_INFO_CLIENT_BY_ID",
      validateToken: "global/helper/GET_VALIDATE_TOKEN",
    }),
    getToken() {
      return this.$route.query.token;
    },
    getRefresh() {
      return this.$route.query.refresh;
    },
    getIp() {
      return ip.address();
    },
    async getIpPublic() {
      return await publicIp.v4();
    },
  },
  async created() {
    try {
      const asyncSessionStorage = {
        setItem: function (key, value) {
          return Promise.resolve().then(function () {
            sessionStorage.setItem(key, value);
          });
        },
      };
      //clear session and clear local storage
      sessionStorage.clear();
      const tokenURL = this.getToken();
      const refreshURL = this.getRefresh();
      const ip = this.getIp();
      const publicIp = await this.getIpPublic();
      if (tokenURL && refreshURL) {
        const {
          data: { validateJWT },
        } = await this.validateToken({
          token: tokenURL,
          ip_public: publicIp,
          ip_local: ip,
        });
        if (validateJWT.code != 0) throw new Error("Error validating tokens!");

        switch (validateJWT.code) {
          case 0:
            if (!validateJWT.decode.username)
              throw new Error("Error validating tokens!");
            //const vueObject = this;
            const clientId = validateJWT.decode.id_cliente;
            const {
              data: { obj },
            } = await this.getInfoClient(clientId);
            if (!obj) {
              throw new Error(
                "Client not found, please send a email to support@isocialcube.com"
              );
            }

            // remove when we finish to store for each project
            asyncSessionStorage.setItem("token", tokenURL).then(function () {
              //vueObject.$store.dispatch("global/setToken", tokenURL);
              asyncSessionStorage
                .setItem("refreshToken", refreshURL)
                .then(function () {
                  asyncSessionStorage
                    .setItem("ip-public", publicIp)
                    .then(function () {
                      asyncSessionStorage
                        .setItem("ip-local", ip)
                        .then(function () {
                          window.dispatchEvent(
                            new CustomEvent("credentials-changed", {
                              detail: {
                                token: sessionStorage.getItem("token"),
                                refreshToken:
                                  sessionStorage.getItem("refreshToken"),
                                ipPublic: sessionStorage.getItem("ip-public"),
                                ipLocal: sessionStorage.getItem("ip-local"),
                              },
                            })
                          );
                          //vueObject.$store.dispatch("global/setRefresh", refreshURL);
                          asyncSessionStorage
                            //.setItem("client_id", validateJWT.decode.id_cliente)
                            .setItem("userObj", JSON.stringify(obj))
                            .then(function () {
                              asyncSessionStorage
                                .setItem(
                                  "username",
                                  validateJWT.decode.username
                                )
                                .then(function () {
                                  asyncSessionStorage
                                    .setItem("name", validateJWT.decode.nombres)
                                    .then(function () {
                                      asyncSessionStorage
                                        .setItem("module", "monitor")
                                        .then(function () {
                                          window.dispatchEvent(
                                            new CustomEvent("user-changed", {
                                              detail: {
                                                name: sessionStorage.getItem(
                                                  "name"
                                                ),
                                                username:
                                                  sessionStorage.getItem(
                                                    "username"
                                                  ),
                                                module:
                                                  sessionStorage.getItem(
                                                    "module"
                                                  ),
                                              },
                                            })
                                          );
                                          // vueObject.$store.dispatch(
                                          //   "global/setName",
                                          //   validateJWT.decode.nombres
                                          // );
                                        });
                                    });
                                });
                            });
                        });
                    });
                });
            });

            this.$router.push({ name: "/overview/accounts" });
            break;
          case 1002:
            console.error("Expired Token!");
            break;
          default:
            throw new Error("Invalid Token!");
        }
      } else {
        throw new Error("Missing query string!");
      }
    } catch (error) {
      console.error("[Error] in function: created [APP AUTH]", error);
      sessionStorage.clear();
      window.location.href = `${process.env.VUE_APP_MONITOR_URL}/login`;
    }
  },
  mounted() {},
};
</script>

<style scoped></style>
